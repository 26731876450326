import React from 'react'

export default function PenTool(props) {
    return (
        <svg className={props.className} width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4233 25.5342C21.0685 23.8889 21.0685 21.2214 19.4233 19.5762C17.778 17.9309 15.1106 17.9309 13.4653 19.5762C11.8201 21.2214 11.8201 23.8889 13.4653 25.5342C15.1106 27.1794 17.778 27.1794 19.4233 25.5342Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 37.0001L13.4653 25.5348" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M28.4946 2.77014L36.2301 10.5057C37.257 11.5325 37.257 13.2131 36.2301 14.2405L35.9163 14.5544C34.8894 15.5812 33.2089 15.5812 32.1815 14.5544L24.4459 6.81883C23.4191 5.79198 23.4191 4.11142 24.4459 3.08401L24.7598 2.77014C25.7866 1.74329 27.4672 1.74329 28.4946 2.77014Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.0242 14.3975L24.6026 6.97586C24.3435 6.71675 24.147 6.41812 24.0087 6.09973L8.63755 14.1124L2 37.0001L24.8876 30.3625L32.9004 14.9914C32.582 14.8531 32.2839 14.6566 32.0242 14.3975Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
