import React, { useMemo, useState } from "react";
import ProjectCard from "./ProjectCard";
import project from "../../../assets/json/portfolio.json";
import { memo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "../../../assets/icons/Icon";

const Portfolio = () => {
  const [filter, setFilter] = useState("all");
  const projects = useMemo(
    () =>
      project.portfolio.filter(
        (x) => filter === "all" || x.type.includes(filter)
      ),
    [filter]
  );
  const buttons = [
    "Custom Dashboard",
    "Forms",
    "Funnels/Websites",
    "Surveys",

  ];
  return (
    <div className={"flex flex-col gap-16 justify-center relative"}>
      {/* Projects Filter Button -- -- */}
      <div className={"flex flex-wrap gap-4 md:gap-2.5 justify-center"}>
        <div className={"project-filter-btn-circle"}>
          <button
            className={`w-max btn btn-circle btn-primary-outline btn-sm ${filter === "all" ? "active" : ""
              }`}
            onClick={() => setFilter("all")}
          >
            <span className={"w-max flex justify-center items-center"}>
              <Icon name={"square group"} />
              <span className={"hidden txt pl-2.5"}>All</span>
            </span>
          </button>
        </div>
        {buttons.map((btn, ind) => {
          return (
            <button
              key={btn + ind}
              className={`project-filter-btn btn btn-primary-outline btn-sm ${filter === btn.toLowerCase() ? "active" : ""
                }`}
              onClick={() => setFilter(btn.toLowerCase())}
            >
              <span>{btn} </span>
            </button>
          );
        })}
      </div>
      {/* Projects Listing -- -- */}
      <motion.div
        layout
        className={`grid ${projects.length ? "md:grid-cols-2 lg:grid-cols-3" : ""} gap-5 capitalize`}
      >
        <AnimatePresence>
          {projects.map((detail) => (
            <ProjectCard
              key={detail.id}
              className={""}
              image={detail.image}
              previewLink={detail.previewLink}
              subheading={detail.subheading}
              heading={detail.heading}
            />
          ))}
        </AnimatePresence>
        {projects.length ? null : (
          <div className="text-center">
            No projects to display at the moment. Check back soon for updates!
          </div>
        )}
      </motion.div>
      {/* Projects Listing -- End -- */}
    </div>
  );
};
export default memo(Portfolio);
