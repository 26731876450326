import React from 'react'

export default function Star(props) {
    return (
        <svg className={props.className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 0.970215L9.26336 6.04316L14.6329 6.15259L10.3532 9.39727L11.9084 14.5378L7.5 11.4702L3.09161 14.5378L4.64683 9.39727L0.367076 6.15259L5.73664 6.04316L7.5 0.970215Z" fill="currentColor"/>
        </svg>

    )
}
