import Logo from "../images/Store/Logo Circle.png";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../icons/Icon";
import { memo } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const randStr = () => Math.random().toString(32).substr(2);
  const menus = ["Home", "About", "Services", "Portfolio", "Contact", "Team"];

  const [activeNav, setActiveNav] = useState("Home");
  const [toggleMenu, setToggleMenu] = useState(false); // State for toggling the menu visibility
  const hammenu = useRef(null);

  useEffect(() => {
    // Add or remove the "open" class based on the menu toggle state
    document.body.style.overflow = toggleMenu ? "hidden" : "auto"; // Disable scrolling when menu is open

    return () => {
      document.body.style.overflow = "auto"; // Restore scroll behavior when cleanup
    };
  }, [toggleMenu]);

  function scrollIntoView(sel) {
    const activeLink = sel.charAt(0).toUpperCase() + sel.slice(1);
    setActiveNav(activeLink);
    const elm = document.querySelector("#" + sel);
    if (elm) {
      elm.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleMenuClick() {
    setToggleMenu((state) => !state);
  }

  const logo = (
    <Link
      to="/"
      onClick={(e) => {
        if (window.location.pathname === "/") {
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }}
    >
      <img
        className="object-cover w-12"
        loading="eager"
        alt="Company Logo"
        src={Logo}
      />
    </Link>
  );

  useEffect(() => {
    const menuLink = document.querySelectorAll(".menuLink");

    function handleMenuMouseEnter({ target }) {
      menuLink.forEach(
        (menu) => target !== menu && menu.classList.add("disable")
      );
    }

    function handleMenuMouseLeave() {
      menuLink.forEach((menu) => menu.classList.remove("disable"));
    }

    menuLink.forEach((menu) => {
      menu.removeEventListener("mouseenter", handleMenuMouseEnter);
      menu.removeEventListener("mouseleave", handleMenuMouseEnter);

      menu.addEventListener("mouseenter", handleMenuMouseEnter);
      menu.addEventListener("mouseleave", handleMenuMouseLeave);
    });
  }, []);

  const company = {
    address: (
      <span>
        541 Melville Geek,
        <br /> Palo Alto, CA 94301
      </span>
    ),
    phone: "+92 366 650 2064",
    email: "info@xortlogix.com",
    social_media: {
      Facebook: "#",
      LinkedIn: "#",
      Instagram: "#",
      Twitter: "#",
    },
  };

  return (
    <header className="sticky top-0 left-0 z-50 w-full bg-transparent sm:px-8 backdrop-filter backdrop-blur-xl">
      <div className="flex items-center justify-between max-w-full px-4 py-5 text-base text-white md:px-8 lg:px-12 font-small">
        {logo}
        <div className="rounded-md hidden lg:flex flex-row flex-wrap items-center justify-center p-1.5 box-border gap-[10px] max-w-full">
          {menus.map((menu) => (
            <Link
              to={`${menu === "Team"
                ? "/team"
                : menu === "Portfolio"
                  ? "/portfolio"
                  : "/"}`}
              key={randStr()}
              onClick={() => {
                scrollIntoView(menu.toLowerCase());
              }}
              className={`btn btn-primary-outline text-sm ${activeNav === menu ? "active" : ""}`}
            >
              {menu}
            </Link>
          ))}
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => navigate("/calendar")}
            className="btn btn-primary !text-[18px]"
          >
            <span className="block p-0.5">Get Started</span>
          </button>
          <div
            className="flex items-center justify-center cursor-pointer lg:hidden"
            onClick={handleMenuClick}
          >
            <Icon
              className={`w-10 ${toggleMenu ? "active" : ""}`}
              name={"hamburger"}
            />
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        ref={hammenu}
        className={`hammenu fixed inset-0 z-40 bg-white transform ${toggleMenu ? "translate-x-0" : "translate-x-full"}
          transition-transform duration-300 ease-in-out lg:hidden`} // Ensure it is hidden initially on mobile
        style={{ display: toggleMenu ? "block" : "none" }} // Toggle display between block and none
      >
        <div className="flex justify-between w-full px-4 py-5 sm:px-10">
          {logo}
          <div
            className="flex items-center justify-center cursor-pointer"
            onClick={handleMenuClick}
          >
            <Icon
              className={`w-10 ${toggleMenu ? "active" : ""}`}
              name={"hamburger"}
            />
          </div>
        </div>

        <div className="flex flex-col h-full p-8">
          {menus.map((menu) => (
            <div key={randStr()} className="py-6 border-t">
              <Link
                to={`${menu === "Team"
                  ? "/team"
                  : menu === "Portfolio"
                    ? "/portfolio"
                    : "/"}`}
                onClick={() => {
                  setTimeout(scrollIntoView, 300, menu.toLowerCase());
                  handleMenuClick();
                }}
                style={{ color: "black" }}
                className="block text-2xl font-semibold tracking-wider text-center uppercase menuLink"
              >
                {menu}
              </Link>
            </div>
          ))}

          <div className="mt-10">
            <div className="mb-8">
              <h6 className="mb-2 sub-title opacity-70">Address</h6>
              <h5 className="text-xl">{company.address}</h5>
            </div>

            <div className="mb-8">
              <h6 className="mb-2 sub-title opacity-70">Social Media</h6>
              <ul className="flex flex-col">
                {Object.keys(company.social_media).map((media) => (
                  <li key={randStr()} className="mb-2">
                    <a href={company.social_media[media]} className="link">
                      {media}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-8">
              <h6 className="mb-2 sub-title opacity-70">Contact Us</h6>
              <h5>
                <a
                  className="link"
                  href={"mailto:" + encodeURIComponent(company.email)}
                >
                  {company.email}
                </a>
              </h5>
              <h5 className="mt-2">
                <a
                  className="link pr-0.5"
                  href={"tel:" + encodeURIComponent(company.phone)}
                >
                  {company.phone}
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default memo(Navbar);
