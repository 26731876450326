import React from 'react'

export default function Highlevel(props) {
    return (
        <svg className={props.className} width="65" height="63" viewBox="0 0 65 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.1045 11.5463L46.1765 11.6157C46.1764 11.6158 46.1763 11.616 46.1762 11.6161L46.1765 11.6157L46.1045 11.5463ZM46.1045 11.5463L46.0326 11.6157C46.0327 11.6158 46.0328 11.616 46.0329 11.6161L46.0326 11.6157L46.1045 11.5463ZM26.6135 19.6897H22.2C21.8134 19.6897 21.5 20.0031 21.5 20.3897V51.9702C21.5 52.2464 21.2761 52.4702 21 52.4702H17C16.7239 52.4702 16.5 52.2464 16.5 51.9702V20.3897C16.5 20.0031 16.1866 19.6897 15.8 19.6897H11.1772L18.8953 11.6903L26.6135 19.6897ZM53.8227 19.6897H49.2C48.8134 19.6897 48.5 20.0031 48.5 20.3897V51.9702C48.5 52.2464 48.2761 52.4702 48 52.4702H44C43.7238 52.4702 43.5 52.2464 43.5 51.9702V20.3897C43.5 20.0031 43.1866 19.6897 42.8 19.6897H38.3864L46.1045 11.6903L53.8227 19.6897ZM53.9862 19.8592C53.9861 19.8591 53.986 19.859 53.986 19.8589L53.9862 19.8592ZM40.2182 34.0312H35.2C34.8134 34.0312 34.5 34.3446 34.5 34.7312V51.9702C34.5 52.2464 34.2761 52.4702 34 52.4702H31C30.7238 52.4702 30.5 52.2464 30.5 51.9702V34.7312C30.5 34.3446 30.1866 34.0312 29.8 34.0312H24.7819L32.5001 26.0318L40.2182 34.0312Z" stroke="currentColor"/>
        </svg>

    )
}
