import React, { useState, useRef, useEffect } from 'react';
import Images from "../../../assets/images/Images";
import Icon from "../../../assets/icons/Icon";
import { motion } from 'framer-motion';

const ProjectCard = ({ image, previewLink, heading, subheading, className }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const modalRef = useRef(null);

    const handleCardClick = (e) => {
        // Prevent default action if previewLink is "#" or empty
        if (previewLink === "#" || !previewLink) {
            e.preventDefault();
            setModalOpen(true);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    // Close modal if clicked outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isModalOpen]);

    return (
        <>
            <motion.a
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                rel={"noreferrer noopener"}
                target={"_blank"}
                href={previewLink}
                className={"portfolio-card"}
                onClick={handleCardClick}
            >
                <div className={`flex flex-col gap-7 p-4 rounded-xl bg-white border ${className}`}>
                    <div className={"flex justify-between"}>
                        <div>
                            <h3>{subheading}</h3>
                            <h2 className={"sub-heading"}>{heading}</h2>
                        </div>
                        <div
                            className={"w-10 h-10 p-1.5 flex justify-center items-center rounded-full bg-black text-white portfolio-link animate-500"}
                        >
                            <span className={"dtr-icon-btn--icon-wrapper"}>
                                <Icon name={"arrow"} className={"dtr-icon dtr-icon-btn--icon"} />
                                <Icon name={"arrow"} className={"dtr-icon dtr-icon-btn--hover-icon"} />
                            </span>
                        </div>
                    </div>
                    <div className={`h-[400px] overflow-hidden rounded-[10px] border relative`}>
                        <Images
                            onLoad={(e) => { e.target.style.setProperty("--img-height", e.target.height - 400) }}
                            className={"relative top-0 left-0 w-full"}
                            src={image}
                        />
                    </div>
                </div>
            </motion.a>

            {/* Modal for image */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
                    <div ref={modalRef} className="relative max-w-3xl max-h-full p-4 bg-white rounded-lg">
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                        >
                            &times;
                        </button>
                        <Images className="w-full h-[1000px]" src={image} />
                    </div>
                </div>
            )}
        </>
    );
};

export default ProjectCard;
