import React from 'react'

export default function Tick(props) {
    return (
        <svg className={props.className} width="43" height="32" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 18.7702L12.9286 29.9702L41.5 1.97021" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
