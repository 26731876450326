import {createContext, useContext} from "react";
const themeMode = "light"
const lightTheme = () => {
};
const darkTheme = () => {
};
const randStr = () => Math.random().toString(32).substr(2);

export const GlobalContext = createContext({
    themeMode,
    lightTheme,
    darkTheme,
    randStr
});

export const GlobalContextProvider = GlobalContext.Provider;
// export const GlobalContextProvider = <GlobalContext.Provider value={{themeMode,lightTheme,darkTheme,randStr}}></GlobalContext.Provider>;

export default function useGlobalContext() {
    return useContext(GlobalContext);
}