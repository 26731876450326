import React from 'react'

export default function SquareGroup(props) {
    return (
        <svg className={props.className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.9187" width="5" height="5" rx="1" fill="currentColor"/>
            <rect x="7.5" y="1.9187" width="5" height="5" rx="1" fill="currentColor"/>
            <rect x="1.5" y="7.9187" width="5" height="5" rx="1" fill="currentColor"/>
            <rect x="7.5" y="7.9187" width="5" height="5" rx="1" fill="currentColor"/>
        </svg>

    )
}
