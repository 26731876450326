import React, {Fragment} from 'react'
import Card from "./Card";
import Icon from "../../../assets/icons/Icon";

export default function About() {
    const randStr = () => Math.random().toString(32).substr(2);
    const aboutWrapperStyle = "wrapper-about sm:absolute animate-300 overflow-hidden sm:h-72 rounded-2xl p-10 sm:pb-8 cursor-pointer flex flex-col gap-6 before:hidden sm:before:block before:absolute before:w-full before:h-24 before:left-0 before:bottom-0 before:z-10 before:pointer-events-none sm:hover:shadow-[0_1px_20px_rgba(0,0,0,15%)] sm:hover:before:hidden sm:hover:scale-105";
    const about = [
        {
            parentCls:" sm:h-72",
            style: {"--current-bg": 'black'},
            image: <Icon name={"group user"}/>,
            heading: "Management",
            className: "bg-black text-lily-900 " + aboutWrapperStyle,
            description: "Our dedicated management team combines experience and vision to guide the company towards success. We prioritize effective communication, strategic decision-making, and fostering a positive work environment.   "
        },
        {
            parentCls:" sm:h-72",
            style: {"--current-bg": 'var(--lily)'},
            image: <Icon name={"scrum"}/>,
            heading: "Digital",
            className: "bg-lily-900 " + aboutWrapperStyle,
            description: "Embracing the digital era, we leverage cutting-edge technologies to optimize processes, enhance efficiency, and keep your business at the forefront of innovation. Our commitment to digital solutions ensures adaptability in an ever-evolving market."
        },
        {
            parentCls:" sm:h-72",
            style: {"--current-bg": 'var(--lily)'},
            image: <Icon name={"pen tool"}/>,
            heading: "Design",
            className: "bg-lily-900 " + aboutWrapperStyle,
            description: "Creativity is at the core of what we do. Our design team brings ideas to life, creating visually appealing and user-centric experiences. From branding to user interface design, we strive for excellence in every detail."
        },
        {
            parentCls:" sm:h-72",
            style: {"--current-bg": 'var(--lily)'},
            image: <Icon name={"scrum"}/>,
            heading: "Scrum",
            className: "bg-lily-900 " + aboutWrapperStyle,
            description: "Adopting the Scrum framework, we prioritize flexibility and responsiveness in project management. Our agile approach ensures that we can adapt to changing requirements efficiently, delivering high-quality results within agreed timelines."
        }
    ]

    function scrollIntoView(sel) {
        const elm = document.querySelector("#" + sel);
        if (elm) {
            elm.scrollIntoView({behavior: 'smooth'});
        }
    }
    return (
        <div className="flex flex-col lg:flex-row gap-20 lg:gap-3 justify-between animate-700">
            <div
                className="flex flex-col gap-6 lg:gap-10 text-center lg:text-left lg:w-96 animate-700 lg:pb-1 h-max lg:sticky lg:top-10">
                <h2 className="heading capitalize font-semibold">
                    We take your business success seriously.
                </h2>
                <div className="para-lg">
                    Building Trust Through Transparency, Collaboration, and Innovation
                </div>
                <div>
                    <button
                        onClick={()=> scrollIntoView('contact')}
                        className="btn btn-primary btn-lg" >
                        <span>Contact us</span>
                    </button>
                </div>
            </div>
            <div
                className="grid sm:grid-cols-2 lg:w-[45rem] gap-5">
                {about.map(details => <Fragment key={randStr()}><Card {...details}/></Fragment>)}
            </div>
        </div>
    )
}
