import React from 'react'

export default function Location(props) {
    return (
        <svg className={props.className} width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0002 3.97021C10.4769 3.97021 6.7959 7.65118 6.7959 12.1745C6.7959 15.1951 10.193 19.7678 13.044 23.0723C13.5346 23.6432 14.2504 23.9702 15.0002 23.9702C15.7499 23.9702 16.4658 23.6432 16.9563 23.0723C17.9036 21.9739 18.7768 20.8909 19.5481 19.8449C19.7518 19.5703 19.6932 19.1847 19.4185 18.981C19.1439 18.7774 18.7583 18.836 18.5546 19.1106C17.8018 20.1288 16.9502 21.1902 16.0214 22.264C15.7654 22.5632 15.392 22.7329 15.0002 22.7329C14.6083 22.7329 14.235 22.5632 13.9789 22.264C10.1992 17.8826 8.03009 14.2047 8.03009 12.1745C8.03009 8.32998 11.1587 5.2044 15.0002 5.2044C18.8416 5.2044 21.9702 8.33307 21.9702 12.1745C21.9702 13.2204 21.4179 14.6675 20.3288 16.4818C20.1529 16.7749 20.2485 17.1513 20.5417 17.3272C20.8348 17.5031 21.2112 17.4074 21.3871 17.1143C22.5935 15.1057 23.2044 13.4426 23.2044 12.1714C23.2044 7.65118 19.5235 3.97021 15.0002 3.97021Z" fill="currentColor"/>
            <path d="M11.0078 12.1747C11.0078 14.3777 12.7974 16.1673 15.0004 16.1673C17.2034 16.1673 18.993 14.3747 18.993 12.1747C18.993 9.9717 17.2034 8.18213 15.0004 8.18213C12.7974 8.18213 11.0078 9.97479 11.0078 12.1747ZM17.7588 12.1747C17.7588 13.6959 16.5215 14.9331 15.0004 14.9331C13.4793 14.9331 12.242 13.6959 12.242 12.1747C12.242 10.6536 13.4793 9.41632 15.0004 9.41632C16.5215 9.41632 17.7588 10.6536 17.7588 12.1747Z" fill="currentColor"/>
        </svg>

    )
}
