import React from 'react'

export default function GroupUser(props) {
    return (
        <svg  className={props.className} width="49" height="39" viewBox="0 0 49 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 37V34.9412C2 26.9817 8.41047 20.5294 16.3182 20.5294C24.2259 20.5294 30.6364 26.9817 30.6364 34.9412V37" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"/>
            <path d="M26.5449 24.647C26.5449 18.9618 31.1238 14.3529 36.7722 14.3529C42.4206 14.3529 46.9994 18.9618 46.9994 24.647V25.6765" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"/>
            <path d="M16.3185 20.5296C20.8372 20.5296 24.5004 16.8425 24.5004 12.2942C24.5004 7.74603 20.8372 4.05896 16.3185 4.05896C11.7998 4.05896 8.13672 7.74603 8.13672 12.2942C8.13672 16.8425 11.7998 20.5296 16.3185 20.5296Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M36.7731 14.353C40.1621 14.353 42.9095 11.5876 42.9095 8.17648C42.9095 4.76531 40.1621 2 36.7731 2C33.3841 2 30.6367 4.76531 30.6367 8.17648C30.6367 11.5876 33.3841 14.353 36.7731 14.353Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
