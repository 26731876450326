import React from "react";
import Image from "../../../assets/images/Images";
import { useNavigate } from "react-router-dom";
export default function Section() {
  const navigate = useNavigate();
  function scrollIntoView(sel) {
    const elm = document.querySelector("#" + sel);
    if (elm) {
      elm.scrollIntoView({ behavior: "smooth" });
    }
  }

  const heading =
    "text-3xl sm:text-4xl md:text-5xl md:leading-[3.75rem] lg:text-4xl xl:text-5xl xl:leading-[3.75rem] py-2.5 animate-700";
  return (
    <div className="flex gap-20 lg:gap-2 flex-col lg:flex-row xl:gap-20 w-full py-5 animate-700">
      <div className={"flex justify-center items-center"}>
        <Image src={"computer3d"} className={"sm:min-w-[370px]"} />
      </div>
      <div className="flex flex-col items-start justify-center p-2.5 pl-0 sm:text-center lg:text-left xl:pl-12 box-border gap-6 w-full  animate-700">
        <div className={"w-full"}>
          <h2 className={heading}>Have Any Projects in Mind?</h2>
          <h2 className={heading}>Don't Be Hesitate to Contact Us</h2>
        </div>
        <div className="py-1.5 para-xlg w-full">
          Are you ready to make something awesome?{" "}
          <br className={"hidden sm:block"} /> Let's get on a call.
        </div>
        <div className="flex sm:items-center flex-row sm:justify-center lg:justify-start gap-[10px] w-full schedule-call-wrapper">
          <button
            onClick={() => navigate("/calendar")}
            className="btn btn-primary py-2.5 w-1/2 sm:w-max"
          >
            <span>Schedule A Call</span>
          </button>
          <button
            onClick={() => scrollIntoView("contact")}
            className="btn btn-white py-2.5 w-1/2 sm:w-max"
          >
            <span>Contact us</span>
          </button>
        </div>
      </div>
    </div>
  );
}
