import React from 'react'

export default function Preloader() {
    return (
        <div className="loader-wrap flex items-center justify-center min-h-screen bg-gray-200">
            <svg
                className="w-full h-24 md:h-32 lg:h-40"
                viewBox="0 0 1000 1000"
                preserveAspectRatio="none"
            >
                <path id="svg" d="M0 2S175 1 500 1s500 1 500 1V0H0Z" fill="#171616"></path>
            </svg>

            <div className="loader-wrap-heading flex justify-center mt-4">
                <div className="load-text text-2xl md:text-3xl lg:text-4xl font-semibold text-gray-700 space-x-1">
                    <span className="animate-bounce inline-block">L</span>
                    <span className="animate-bounce inline-block">o</span>
                    <span className="animate-bounce inline-block">a</span>
                    <span className="animate-bounce inline-block">d</span>
                    <span className="animate-bounce inline-block">i</span>
                    <span className="animate-bounce inline-block">n</span>
                    <span className="animate-bounce inline-block">g.</span>
                </div>
            </div>
        </div>
    )
}
