import React from 'react'

export default function Automation(props) {
    return (
        <svg className={props.className} width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.3783 27.5356V13.6212C42.3783 11.8968 40.975 10.4934 39.2506 10.4934H13.1278C11.4034 10.4934 10 11.8968 10 13.6212V27.5356C10 29.26 11.4034 30.6634 13.1278 30.6634H14.1734C14.3385 30.6634 14.4761 30.7734 14.5128 30.9294C15.0906 34.2131 17.2461 35.2037 17.7048 35.378C17.7873 35.4238 17.879 35.4422 17.9708 35.4422C17.9983 35.4422 18.0258 35.4422 18.0533 35.433C18.2918 35.3963 18.4844 35.2129 18.5394 34.9744C18.5945 34.7176 18.6312 34.4607 18.6495 34.2039C18.8146 32.4061 19.1723 30.6542 21.4379 30.6542H39.2598C40.975 30.6634 42.3783 29.26 42.3783 27.5356ZM21.4379 29.5627C18.0166 29.5627 17.6864 32.6721 17.558 34.0571C16.9251 33.6352 15.9437 32.6813 15.6043 30.7368C15.4759 30.0488 14.8797 29.5535 14.1826 29.5627H13.1278C12.0087 29.5627 11.1007 28.6546 11.1007 27.5356V13.6212C11.1007 12.5022 12.0087 11.5941 13.1278 11.5941H39.2506C40.3696 11.5941 41.2777 12.5022 41.2777 13.6212V27.5356C41.2777 28.6546 40.3696 29.5627 39.2506 29.5627H21.4379Z" fill="currentColor"/>
            <path d="M38.6085 21.9772H32.0044C31.7018 21.9772 31.4541 22.2248 31.4541 22.5275C31.4541 22.8302 31.7018 23.0779 32.0044 23.0779H38.6085C38.9112 23.0779 39.1589 22.8302 39.1589 22.5275C39.1589 22.2248 38.9112 21.9772 38.6085 21.9772Z" fill="currentColor"/>
            <path d="M18.4751 21.9772C18.1725 21.9772 17.9248 22.2248 17.9248 22.5275C17.9248 22.8302 18.1725 23.0779 18.4751 23.0779H28.8857C29.1884 23.0779 29.4361 22.8302 29.4361 22.5275C29.4361 22.2248 29.1884 21.9772 28.8857 21.9772H18.4751Z" fill="currentColor"/>
            <path d="M13.7701 23.0779H16.0448C16.3475 23.0779 16.5951 22.8302 16.5951 22.5275C16.5951 22.2248 16.3475 21.9772 16.0448 21.9772H13.7701C13.4674 21.9772 13.2197 22.2248 13.2197 22.5275C13.2197 22.8302 13.4674 23.0779 13.7701 23.0779Z" fill="currentColor"/>
            <path d="M36.8843 19.8584H38.6087C38.9114 19.8584 39.1591 19.6107 39.1591 19.308C39.1591 19.0053 38.9114 18.7577 38.6087 18.7577H36.8843C36.5816 18.7577 36.334 19.0053 36.334 19.308C36.334 19.6107 36.5816 19.8584 36.8843 19.8584Z" fill="currentColor"/>
            <path d="M34.4719 19.8584C34.7745 19.8584 35.0222 19.6107 35.0222 19.308C35.0222 19.0053 34.7745 18.7577 34.4719 18.7577H31.5826C31.2799 18.7577 31.0322 19.0053 31.0322 19.308C31.0322 19.6107 31.2799 19.8584 31.5826 19.8584H34.4719Z" fill="currentColor"/>
            <path d="M13.7701 19.8584H29.1429C29.4456 19.8584 29.6932 19.6107 29.6932 19.308C29.6932 19.0053 29.4456 18.7577 29.1429 18.7577H13.7701C13.4674 18.7577 13.2197 19.0053 13.2197 19.308C13.2197 19.6107 13.4674 19.8584 13.7701 19.8584Z" fill="currentColor"/>
            <path d="M37.2144 15.9876H38.6086C38.9113 15.9876 39.1589 15.74 39.1589 15.4373C39.1589 15.1346 38.9113 14.887 38.6086 14.887H37.2144C36.9117 14.887 36.6641 15.1346 36.6641 15.4373C36.6641 15.74 36.9117 15.9876 37.2144 15.9876Z" fill="currentColor"/>
            <path d="M28.794 15.9876H34.7652C35.0679 15.9876 35.3155 15.74 35.3155 15.4373C35.3155 15.1346 35.0679 14.887 34.7652 14.887H28.794C28.4913 14.887 28.2437 15.1346 28.2437 15.4373C28.2437 15.74 28.4913 15.9876 28.794 15.9876Z" fill="currentColor"/>
            <path d="M13.7701 15.9876H26.3545C26.6572 15.9876 26.9049 15.74 26.9049 15.4373C26.9049 15.1346 26.6572 14.887 26.3545 14.887H13.7701C13.4674 14.887 13.2197 15.1346 13.2197 15.4373C13.2197 15.74 13.4674 15.9876 13.7701 15.9876Z" fill="currentColor"/>
            <path d="M32.1694 25.2058H31.9034C31.6007 25.2058 31.353 25.4535 31.353 25.7562C31.353 26.0588 31.6007 26.3065 31.9034 26.3065H32.1694C32.4721 26.3065 32.7197 26.0588 32.7197 25.7562C32.7197 25.4535 32.4721 25.2058 32.1694 25.2058Z" fill="currentColor"/>
            <path d="M29.6657 25.2058H24.896C24.5934 25.2058 24.3457 25.4535 24.3457 25.7562C24.3457 26.0588 24.5934 26.3065 24.896 26.3065H29.6657C29.9683 26.3065 30.216 26.0588 30.216 25.7562C30.216 25.4535 29.9683 25.2058 29.6657 25.2058Z" fill="currentColor"/>
            <path d="M21.8509 25.2058H13.7701C13.4674 25.2058 13.2197 25.4535 13.2197 25.7562C13.2197 26.0588 13.4674 26.3065 13.7701 26.3065H21.8509C22.1536 26.3065 22.4012 26.0588 22.4012 25.7562C22.4012 25.4535 22.1536 25.2058 21.8509 25.2058Z" fill="currentColor"/>
            <path d="M54.9718 44.6878C54.8067 43.0551 53.9169 41.5784 52.5411 40.6795V36.8271C53.9628 36.5611 54.9993 35.3229 54.9993 33.8736C54.9993 32.2134 53.6509 30.8651 51.9908 30.8651C50.3306 30.8651 48.9822 32.2134 48.9822 33.8736C48.9822 35.3229 50.0187 36.5611 51.4404 36.8271V40.12C51.0001 39.9549 50.5507 39.854 50.0829 39.8081C50.0829 37.9828 48.6062 36.5061 46.7809 36.5061H42.1672V34.7267C42.1672 33.9929 41.571 33.3967 40.8372 33.3967H36.9481V31.6631C36.9481 31.3604 36.7005 31.1128 36.3978 31.1128C36.0951 31.1128 35.8474 31.3604 35.8474 31.6631V33.3967H32.0226C31.2888 33.3967 30.6926 33.9929 30.6926 34.7267V36.5061H26.0789C24.2536 36.5061 22.7769 37.9828 22.7769 39.8081V50.6682C22.7769 52.4935 24.2536 53.9702 26.0789 53.9702H46.7809C48.6062 53.9702 50.0829 52.4935 50.0829 50.6682C53.0914 50.3655 55.2744 47.6872 54.9718 44.6878ZM31.8024 34.7175C31.8024 34.5891 31.9033 34.4882 32.0317 34.4882H40.8464C40.9748 34.4882 41.0757 34.5891 41.0757 34.7175V36.4969H31.8024V34.7175ZM48.9914 50.6682C48.9914 51.8789 48.0008 52.8695 46.79 52.8695H26.0881C24.8773 52.8695 23.8867 51.8789 23.8867 50.6682V39.799C23.8867 38.5882 24.8773 37.5976 26.0881 37.5976H46.79C48.0008 37.5976 48.9914 38.5882 48.9914 39.799V50.6682ZM50.0921 33.8645C50.0921 32.8096 50.9451 31.9566 51.9999 31.9566C53.0547 31.9566 53.9078 32.8096 53.9078 33.8645C53.9078 34.9193 53.0547 35.7723 51.9999 35.7723C50.9451 35.7631 50.0921 34.9193 50.0921 33.8645ZM50.0921 49.5583V40.8996C52.2659 41.1748 53.8986 43.0276 53.9078 45.229C53.8986 47.4304 52.2659 49.2832 50.0921 49.5583Z" fill="currentColor"/>
            <path d="M29.6564 42.3489H29.1152C28.1063 42.3489 27.2808 43.1744 27.2808 44.1833V45.7518C27.2808 46.0545 27.5284 46.3022 27.8311 46.3022H30.9405C31.2432 46.3022 31.4909 46.0545 31.4909 45.7518V44.1833C31.4909 43.1744 30.6654 42.3489 29.6564 42.3489ZM30.3902 45.2015H28.3814V44.1833C28.3814 43.7798 28.7116 43.4496 29.1152 43.4496H29.6564C30.06 43.4496 30.3902 43.7798 30.3902 44.1833V45.2015Z" fill="currentColor"/>
            <path d="M43.7633 42.3489H43.2222C42.2132 42.3489 41.3877 43.1744 41.3877 44.1833V45.7518C41.3877 46.0545 41.6353 46.3022 41.938 46.3022H45.0475C45.3501 46.3022 45.5978 46.0545 45.5978 45.7518V44.1833C45.5978 43.1744 44.7723 42.3489 43.7633 42.3489ZM42.4884 45.2015V44.1833C42.4884 43.7798 42.8186 43.4496 43.2222 43.4496H43.7633C44.1669 43.4496 44.4971 43.7798 44.4971 44.1833V45.2015H42.4884Z" fill="currentColor"/>
            <path d="M39.5533 47.6873H33.3252C33.0226 47.6873 32.7749 47.9349 32.7749 48.2376V48.7788C32.7749 50.5032 34.1691 51.8974 35.8935 51.8974H36.9758C38.7002 51.8974 40.0944 50.5032 40.0944 48.7788V48.2376C40.1036 47.9349 39.8559 47.6873 39.5533 47.6873ZM36.985 50.7967H35.8935C34.7836 50.7967 33.8848 49.8978 33.8756 48.7879H38.9937C38.9937 49.8978 38.0949 50.7967 36.985 50.7967Z" fill="currentColor"/>
        </svg>

    )
}
