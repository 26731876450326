import React from 'react'

export default function Facebook(props) {
    return (
        <svg className={props.className} width="25" height="26" viewBox="0 0 25 26" fill="none" >
            <rect y="0.970703" width="25" height="25" fill="url(#pattern0)"/>
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_329_22" transform="scale(0.0111111)"/>
                </pattern>
                <image id="image0_329_22" width="90" height="90" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABuklEQVR4nO3YsWoUYRhG4YMEWwOpLLQyF2DhBcQrsLK1s/ESBMsUNnZWNnZ2lhHJJYgiNiJqI5ZhIWAkRfJKYIsQJxlmnFl/vpwHvi4MyeFlMyxIkiRJkiZ1DbgPPAN2gY/AD+AXEGAB7AHfgC/AO2AHeAE8mPZXqekO8Ao4XAYdc2/+9x/R+oJfAsf/ENjQPW4BXycIbOgL3AR+ThjZj44OV4EPE0c2dIcnM0Q29BkbwL6h5/d4psgu+ozPhp7f5oyRXfQpDw29Gs9Hhn4L3AOuL18N1WN3ROTtvofqb+8HRj75+Ssdz1GP7wNDP+p7oLotBoa+fc5z1ONoYOj1vgeq29B/hGvnPEcTh9ZIMfRqxNCGLiUuejXhMvM9pag0diffDpaUxm6LotLY3aCoNHQHlb/pS0P3icLS0L2msDR0ZV/taCBuLsOrHQ3Ezam7S2Fp6Mq+2tFA3Czvd+VXuzEy8GTotsVFG7qUuGhDlxIXbehS4qINXUpctKFLiYs2dClx0YYuJS7a0KXERRu6lLhoQ5cSF23oUuKiDV1KXLShS4mLNnQpcdGGLiUu2tClxEUbWpIkSZIo5w8aluW14DcdrAAAAABJRU5ErkJggg=="/>
            </defs>
        </svg>

    )
}
