import React, { useState, useMemo } from "react";
import Teams from "../../assets/images/teams1.png";
import TeamArray from "./teams.json";
import Image from "../../assets/images/Images";
import Icon from "../../assets/icons/Icon";

function Team() {
  const [filter, setFilter] = useState("all");

  // Function to determine if a member matches the current filter
  const matchesFilter = (member, filterLowerCase) => {
    const designationLower = member.designation.toLowerCase();
    const subdesignationLower = member.subdesignation ? member.subdesignation.toLowerCase() : '';



    // Special case for 'Director' filter
    if (filterLowerCase === "director" && (designationLower === "ceo" || designationLower === "co-founder" || designationLower === "ceo & co-founder")) {
      return true;
    }

    // Special case for 'Marketing' filter
    if (filterLowerCase === "marketing" && designationLower === "business development officer") {
      return true;
    }
    // Check for Managing Director in GHL Expert
    if (filterLowerCase === "ghl expert" && subdesignationLower === "managing director") {
      return true;
    }

    // Check for Development Manager and CTO in Developer
    if (filterLowerCase === "developer" && (subdesignationLower === "development manager" || subdesignationLower === "cto")) {
      return true;
    }

    // General case: match either designation or sub-designation
    return designationLower.includes(filterLowerCase) || subdesignationLower.includes(filterLowerCase);
  };

  // Filter team members based on designation or sub-designation
  const filteredTeam = useMemo(() => {
    return TeamArray.data.filter((member) => {
      if (filter === "all") return true;
      const filterLowerCase = filter.toLowerCase();
      return matchesFilter(member, filterLowerCase);
    });
  }, [filter]);

  // Define filter buttons
  const buttons = [
    "All",
    "Director",
    "GHL Expert",
    "Developer",
    "Designer",
    "Marketing",
  ];

  return (
    <div>
      <h1 className="text-center mt-10 text-black-700 text-6xl font-bold">
        Our Team Members
      </h1>
      <img src={Teams} width="50%" className="mx-auto mt-10" alt="Team" />
      <p className="mt-10 text-center text-black-500 mx-auto">
        Our team comprises a diverse array of individuals united by a shared
        passion for technology and a commitment to exceeding our clients'
        expectations. Spanning roles from software engineers and designers to
        project managers and business analysts, each team member contributes a
        distinctive skill set and wealth of expertise. We cultivate an
        environment that values collaboration and inclusivity, nurturing
        innovation and imagination. This ethos empowers us to collaborate
        seamlessly and produce top-tier software solutions tailored to our
        clients' specific requirements.
      </p>
      <div className="w-full h-12 sm:h-[4.5rem]"></div>
      <div className="flex flex-col justify-center relative sm:bg-off-white-900 py-10 sm:py-14 px-7 sm:px-8 lg:px-10 xl:px-14 rounded-[2rem] animate-700">
        {/* Projects Filter Button */}
        <div className="flex flex-wrap gap-4 md:gap-2.5 justify-center">
          <div className="project-filter-btn-circle">
            <button
              className={`w-max btn btn-circle btn-primary-outline btn-sm ${filter === "all" ? "active" : ""}`}
              onClick={() => setFilter("all")}
            >
              <span className="w-max flex justify-center items-center">
                <Icon name="square group" />
                <span className="hidden txt pl-2.5">All</span>
              </span>
            </button>
          </div>
          {buttons.map((btn, ind) => {
            return (
              <button
                key={btn + ind}
                className={`project-filter-btn btn btn-primary-outline btn-sm ${filter === btn.toLowerCase() ? "active" : ""}`}
                onClick={() => setFilter(btn.toLowerCase())}
              >
                <span>{btn} </span>
              </button>
            );
          })}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-12 mt-20">
          {filteredTeam.map((item, index) => (
            <div className="group h-96 w-96 [perspective:1000px]" key={index}>
              <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                {/* Front Side */}
                <div className="absolute inset-0">
                  <Image
                    className="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40"
                    src={item.img}


                  />
                </div>
                {/* Back Side */}
                <div className="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                  <div className="flex min-h-full flex-col items-center justify-center">
                    <h1 className="text-3xl font-bold">{item.name}</h1>
                    <p className="text-1xl font-bold">{item.designation}</p>
                    {item.subdesignation && (
                      <p className="text-lg">{item.subdesignation}</p>
                    )}
                    <div className="flex flex-row gap-2 mt-2">
                      {item.facebook && (
                        <a
                          href={item.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="30"
                            height="30"
                            viewBox="0 0 50 50"
                            fill="white"
                          >
                            <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                          </svg>
                        </a>
                      )}
                      {item.linkedin && (
                        <a
                          href={item.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="30"
                            height="30"
                            viewBox="0,0,256,256"
                          >
                            <g
                              fill="#ffffff"
                              fillRule="nonzero"
                              stroke="none"
                              strokeWidth="1"
                              strokeLinecap="butt"
                              strokeLinejoin="miter"
                              strokeMiterlimit="10"
                              strokeDasharray=""
                              strokeDashoffset="0"
                              fontFamily="none"
                              fontWeight="none"
                              fontSize="none"
                              textAnchor="none"
                            >
                              <g transform="scale(5.12,5.12)">
                                <path d="M41,4h-32c-2.76,0 -5,2.24 -5,5v32c0,2.76 2.24,5 5,5h32c2.76,0 5,-2.24 5,-5v-32c0,-2.76 -2.24,-5 -5,-5zM17,20v19h-6v-19zM11,14.47c0,-1.4 1.2,-2.47 3,-2.47c1.8,0 2.93,1.07 3,2.47c0,1.4 -1.12,2.53 -3,2.53c-1.8,0 -3,-1.13 -3,-2.53zM39,39h-6c0,0 0,-9.26 0,-10c0,-2 -1,-4 -3.5,-4.04h-0.08c-2.42,0 -3.42,2.06 -3.42,4.04c0,0.91 0,10 0,10h-6v-19h6v2.56c0,0 1.93,-2.56 5.81,-2.56c3.97,0 7.19,2.73 7.19,8.22z"></path>
                              </g>
                            </g>
                          </svg>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
