import React, { Fragment } from 'react'
import Icon from "../../../assets/icons/Icon";
import Card from "./Card";
import useGlobalContext from "../../../context/GlobalContext";

export default function Services() {
    useGlobalContext();

    const serviceCLs = "flex flex-col gap-5 p-5 no-animation bg-white rounded-xl h-full border";
    const services = [{
        parentCls: "col-span-2 ",
        className: serviceCLs,
        image: <Icon name='highlevel' />,
        heading: "GHL Account Setup",
        description: "Unlock the full potential of your business with our seamless GoHighLevel account setup, designed to streamline your operations and boost your growth effortlessly. Start transforming your customer engagement today!"
    },
    {
        parentCls: "col-span-2 ",
        className: serviceCLs,
        image: <Icon name='registration ' />,
        heading: "Migration to GHL",
        description: "Effortlessly migrate to GoHighLevel and elevate your business with our expert services, ensuring a smooth transition and enhanced customer engagement. Transform your operations today!"
    },

    {
        parentCls: "col-span-2",
        className: serviceCLs,
        image: <Icon name='Dashboard' />,
        heading: "GHL Dashboards customize",
        description: "Discover the power of our custom GoHighLevel dashboards, providing real-time insights and analytics to drive your business success. Elevate your decision-making with data at your fingertips!"
    },
    {
        parentCls: "col-span-2",
        className: serviceCLs,
        image: <Icon name='Funnels' />,
        heading: "Funnels / Websites",
        description: "Transform your vision into a captivating online experience with our GHL Funnels and Web Design services, tailored to drive conversions and elevate your brand. Let's create something extraordinary together!"
    },
    {
        parentCls: "col-span-2",
        className: serviceCLs,
        image: <Icon name='SEO' />,
        heading: "3rd party integration",
        description: "Seamlessly connect your business with powerful third-party integrations, enhancing functionality and streamlining operations for a more efficient workflow. Let's unlock new possibilities together!"
    },
    {
        parentCls: "col-span-2",
        className: serviceCLs,
        image: <Icon name='Web Development' />,
        heading: "Web Development",
        description: "Turn concepts into dynamic websites with advanced features and seamless functionality."
    }, {
        parentCls: "col-span-2 lg:col-span-3",
        className: serviceCLs,
        image: <Icon name='Add-ons' />,
        heading: "custom GHL Add-ons ",
        description: "Enhance your GoHighLevel platform with our custom add-ons, crafted to meet your unique business needs and supercharge your workflow. Let's customize and elevate your success!"
    },
    {
        parentCls: "col-span-2  lg:col-span-3",
        className: serviceCLs,
        image: <Icon name='Graphic Designing' />,
        heading: "SAAS",
        description: "Elevate your business with our cutting-edge SaaS solutions, designed to optimize performance and drive growth effortlessly. Let's innovate and thrive together!"
    },

    {
        parentCls: "col-span-2 lg:col-span-3",
        className: serviceCLs,
        image: <Icon name='GHL Automation' />,
        heading: "GHL Automation",
        description: "Revolutionize your business with our GHL Automation services, designed to streamline processes and boost efficiency effortlessly. Let's automate and elevate your success!"
    },


    {
        parentCls: "col-span-2 lg:col-span-3",
        className: serviceCLs,
        image: <Icon name='API Integration' />,
        heading: "API Integration",
        description: "Connect software applications effortlessly for enhanced overall functionality and performance."
    },]
    return (
        <div className={"grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-5"} >
            {services.map((service, ind) => <Fragment key={'s' + ind}><Card {...service} /></Fragment>)}
        </div>
    )
}
