import React from 'react'

export default function Instagram(props) {
    return (
        <svg className={props.className} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4795 0.970703H4.5203C2.02785 0.970703 0 2.99856 0 5.491V21.4499C0 23.9423 2.02785 25.9702 4.5203 25.9702H20.4797C22.9721 25.9702 25 23.9423 25 21.4499V5.491C24.9998 2.99831 22.9721 0.970703 20.4795 0.970703ZM23.9952 21.4497C23.9952 23.3884 22.4182 24.9654 20.4795 24.9654H4.5203C2.58159 24.9654 1.00451 23.3884 1.00451 21.4497V5.491C1.00451 3.5523 2.58159 1.97521 4.5203 1.97521H20.4797C22.4184 1.97521 23.9955 3.5523 23.9955 5.491L23.9952 21.4497Z" fill="currentColor"/>
            <path d="M17.0315 9.27751C16.8132 9.44878 16.7753 9.76445 16.9468 9.98268C17.7346 10.9857 18.151 12.1916 18.151 13.4703C18.151 16.5863 15.6161 19.1212 12.5004 19.1212C9.38437 19.1212 6.84924 16.5863 6.84924 13.4703C6.84924 10.3546 9.38412 7.81947 12.5001 7.81896C13.1759 7.81896 13.8369 7.93699 14.4647 8.16979C14.7248 8.26698 15.0139 8.13363 15.1103 7.87371C15.2068 7.61354 15.0742 7.32449 14.8142 7.22806C14.0744 6.95358 13.2957 6.81445 12.5001 6.81445C8.83038 6.81496 5.84473 9.80086 5.84473 13.4703C5.84473 17.1401 8.83038 20.1257 12.5004 20.1257C16.1701 20.1257 19.1555 17.1401 19.1555 13.4703C19.1555 11.9646 18.6648 10.5439 17.7366 9.36239C17.5656 9.14416 17.2497 9.10624 17.0315 9.27751Z" fill="currentColor"/>
            <path d="M7.27395 5.94458C7.27395 4.67689 6.24232 3.64551 4.97438 3.64551C3.70644 3.64551 2.6748 4.67714 2.6748 5.94508C2.6748 7.21252 3.70619 8.24416 4.97413 8.24441C6.24232 8.24441 7.27395 7.21277 7.27395 5.94458ZM4.97438 7.2399C4.26042 7.2399 3.67931 6.65879 3.67931 5.94508C3.67931 5.23088 4.26017 4.65002 4.97438 4.65002C5.68859 4.65002 6.26944 5.23088 6.26944 5.94458C6.26944 6.65879 5.68834 7.2399 4.97438 7.2399Z" fill="currentColor"/>
            <path d="M7.53467 13.4699C7.53467 16.2077 9.76217 18.435 12.4997 18.435C15.2373 18.435 17.4648 16.2075 17.4648 13.4699C17.4648 10.7324 15.2375 8.50488 12.4997 8.50488C9.76192 8.50488 7.53467 10.7321 7.53467 13.4699ZM16.4602 13.4699C16.4602 15.6537 14.6835 17.4305 12.4997 17.4305C10.3159 17.4305 8.53918 15.6537 8.53918 13.4699C8.53918 11.2861 10.3159 9.50939 12.4997 9.50939C14.6835 9.50939 16.4602 11.2861 16.4602 13.4699Z" fill="currentColor"/>
        </svg>

    )
}
